import * as i0 from '@angular/core';
import { Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Import Angular stuff
const _c0 = ["*"];
class LayoutComponent {
  static ɵfac = function LayoutComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || LayoutComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: LayoutComponent,
    selectors: [["app-layout"]],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function LayoutComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    styles: ["[_nghost-%COMP%]{display:flex;flex-direction:column;width:100%;height:calc(100vh - 64px);overflow-x:hidden;overflow-y:scroll}@media (max-width: 600px){[_nghost-%COMP%]{height:calc(100vh - 56px)}}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LayoutComponent, [{
    type: Component,
    args: [{
      selector: 'app-layout',
      template: "<ng-content></ng-content>\r\n",
      styles: [":host{display:flex;flex-direction:column;width:100%;height:calc(100vh - 64px);overflow-x:hidden;overflow-y:scroll}@media (max-width: 600px){:host{height:calc(100vh - 56px)}}\n"]
    }]
  }], null, null);
})();

// Import Angular stuff
class HeaderComponent {
  color = 'none';
  static ɵfac = function HeaderComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || HeaderComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: HeaderComponent,
    selectors: [["app-header"], ["header"]],
    inputs: {
      color: "color"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function HeaderComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    styles: ["[_nghost-%COMP%]{width:calc(100% - 32px);margin:auto;padding:16px;flex:0 0 auto;display:flex}[_nghost-%COMP%]     h1, [_nghost-%COMP%]     h2, [_nghost-%COMP%]     h3{font-weight:400;margin-block-end:0px}[_nghost-%COMP%]    >:first-child{flex-grow:1}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HeaderComponent, [{
    type: Component,
    args: [{
      selector: 'app-header, header',
      template: "<ng-content></ng-content>\r\n",
      styles: [":host{width:calc(100% - 32px);margin:auto;padding:16px;flex:0 0 auto;display:flex}:host ::ng-deep h1,:host ::ng-deep h2,:host ::ng-deep h3{font-weight:400;margin-block-end:0px}:host ::ng-deep>:first-child{flex-grow:1}\n"]
    }]
  }], null, {
    color: [{
      type: Input
    }]
  });
})();

// Import Angular stuff
class FooterComponent {
  color = 'none';
  static ɵfac = function FooterComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || FooterComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: FooterComponent,
    selectors: [["app-footer"], ["footer"]],
    inputs: {
      color: "color"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function FooterComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    styles: ["[_nghost-%COMP%]{width:calc(100% - 32px);margin:auto;padding:16px;flex:0 0 auto}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FooterComponent, [{
    type: Component,
    args: [{
      selector: 'app-footer, footer',
      template: "<ng-content></ng-content>\r\n",
      styles: [":host{width:calc(100% - 32px);margin:auto;padding:16px;flex:0 0 auto}\n"]
    }]
  }], null, {
    color: [{
      type: Input
    }]
  });
})();

// Import Angular stuff
class ContentComponent {
  static ɵfac = function ContentComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ContentComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: ContentComponent,
    selectors: [["app-content"], ["app-main"], ["main"]],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function ContentComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    styles: ["[_nghost-%COMP%]{width:calc(100% - 32px);margin:auto;padding:16px;flex:1 1 auto}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContentComponent, [{
    type: Component,
    args: [{
      selector: 'app-content, app-main, main',
      template: "<ng-content></ng-content>\r\n",
      styles: [":host{width:calc(100% - 32px);margin:auto;padding:16px;flex:1 1 auto}\n"]
    }]
  }], null, null);
})();

// Import Angular stuff
class LayoutModule {
  static ɵfac = function LayoutModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || LayoutModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: LayoutModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LayoutModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [LayoutComponent, HeaderComponent, FooterComponent, ContentComponent],
      exports: [LayoutComponent, HeaderComponent, FooterComponent, ContentComponent]
    }]
  }], null, null);
})();

/**
 * Public surface API of @public/core/ui/layout
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ContentComponent, FooterComponent, HeaderComponent, LayoutComponent, LayoutModule };
