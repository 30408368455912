// Import Angular stuff

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Import RxJS stuff

import { Subject, Observable,ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LocalStorage } from '@public/core';

import { AuthenticationService } from '@platform/shared/authentication';

const MEMBER_KEY = 'currentMember';

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  private readonly PATH = '/api/members';

  private authenticationStateSource: ReplaySubject<any> = new ReplaySubject(1);
  
  /**
   * An `Observable` that one can subscribe to get the current logged in user information.
   */

  get authenticationStateChange(): Observable<any> {
    return this.authenticationStateSource.asObservable();
  }

  private unsubscriber = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private localStorage: LocalStorage,
    private authenticationService: AuthenticationService
  ) {
    this.get('me')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe({
        next: member => {
          this.setCurrentMember(member);
        },
      });

    this.authenticationService.authenticationStateChange.pipe(takeUntil(this.unsubscriber)).subscribe(user => {
      this.get('me')
        .pipe(takeUntil(this.unsubscriber))
        .subscribe({
          next: member => {
            this.setCurrentMember(member);
          },
        });
    });
  }

  public ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  public getCurrentMember(): any {
    return this.localStorage.get(MEMBER_KEY);
  }

  protected setCurrentMember(member: any): void {
    this.localStorage.set(MEMBER_KEY, member);
    this.authenticationStateSource.next(member);
  }

  public search(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.PATH);
  }

  public get(key: string): Observable<any> {
    return this.httpClient.get<any>(`${this.PATH}/${key}`);
  }

  public post(payload: any): Observable<any> {
    return this.httpClient.post<any>(this.PATH, payload);
  }

  public put(key: string, payload: any): Observable<any> {
    return this.httpClient.put<any>(this.PATH + '/' + key, payload);
  }

  public practices(key: string): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.PATH}/${key}/practices`);
  }
}
