// Import Angular stuff

import { Component } from '@angular/core';

@Component({
  selector: 'footer-content',
  templateUrl: './footer-content.component.html',
  styleUrls: ['./footer-content.component.scss'],
})
export class FooterContentComponent {}
