import { Pipe, PipeTransform } from '@angular/core';

const levels = [
  undefined,
  '8. kyu ho',
  '8. kyu',
  '7. kyu ho', // 3
  '7. kyu',
  '6. kyu ho',
  '6. kyu', // 6
  '5. kyu ho',
  '5. kyu',
  '4. kyu ho', // 9
  '4. kyu',
  '3. kyu',
  '2. kyu', // 12
  '1. kyu',
  '1. dan',
  '2. dan', // 15
  '3. dan',
  '4. dan',
  '5. dan', // 18
  '6. dan',
  '7. dan',
  '8. dan', // 21
  '9. dan',
  '10. dan',
];

@Pipe({ name: 'levelToText' })
export class LevelToTextPipe implements PipeTransform {
  transform(level: number | undefined): string | undefined {
    if (level && level < levels.length) {
      return levels[level];
    } else {
      return undefined;
    }
  }
}
