import * as i0 from '@angular/core';
import { Directive, InjectionToken, Injectable, Optional, Inject, Component, ViewChild, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as i2 from '@angular/router';
import { NavigationEnd, NavigationError, RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as i1 from '@angular/cdk/layout';
import { Breakpoints } from '@angular/cdk/layout';
function SidenavComponent_ng_template_0_Template(rf, ctx) {}
class SidenavContentDirective {
  viewContainerRef;
  constructor(viewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }
  static ɵfac = function SidenavContentDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SidenavContentDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: SidenavContentDirective,
    selectors: [["", "sidenav-content", ""]]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SidenavContentDirective, [{
    type: Directive,
    args: [{
      selector: '[sidenav-content]'
    }]
  }], () => [{
    type: i0.ViewContainerRef
  }], null);
})();

// Import Angular stuff
/** Injection token that can be used to configure the sidenav's behavior. */
const SIDENAV_CONFIG = new InjectionToken('SidenavConfig');
const defaultSidenavOver = [Breakpoints.Handset, Breakpoints.TabletPortrait];
class Sidenav {
  breakpointState;
  contentChangeSource = new BehaviorSubject(undefined);
  stateChangeSource = new BehaviorSubject(undefined);
  modeChangeSource = new BehaviorSubject(undefined);
  destroyer = new ReplaySubject(1);
  get content() {
    return this.contentChangeSource.value;
  }
  set content(component) {
    this.contentChangeSource.next(component);
  }
  get state() {
    return this.stateChangeSource.value;
  }
  set state(state) {
    this.stateChangeSource.next(state);
  }
  get mode() {
    return this.modeChangeSource.value;
  }
  set mode(mode) {
    this.modeChangeSource.next(mode);
  }
  get contentChange() {
    return this.contentChangeSource.asObservable();
  }
  get stateChange() {
    return this.stateChangeSource.asObservable();
  }
  get modeChange() {
    return this.modeChangeSource.asObservable();
  }
  constructor(breakpointObserver, router, sidenavOver) {
    if (!sidenavOver) {
      sidenavOver = defaultSidenavOver;
    }
    if (sidenavOver.length > 0) {
      if (breakpointObserver.isMatched(sidenavOver)) {
        this.mode = 'over';
        this.close();
      } else {
        this.mode = 'side';
        this.open();
      }
      breakpointObserver.observe(sidenavOver).pipe(takeUntil(this.destroyer)).subscribe(state => {
        this.breakpointState = state;
        if (state.matches) {
          this.mode = 'over';
          this.close();
        } else {
          this.mode = 'side';
          this.open();
        }
      });
    }
    router.events.pipe(takeUntil(this.destroyer)).subscribe(event => {
      if (this.mode === 'over' && (event instanceof NavigationEnd || event instanceof NavigationError)) {
        this.close();
      }
    });
  }
  ngOnDestroy() {
    this.destroyer.next(true);
    this.destroyer.complete();
  }
  open() {
    this.state = 'open';
  }
  close() {
    this.state = 'closed';
  }
  toggle() {
    this.state = this.state === 'open' ? 'closed' : 'open';
  }
  static ɵfac = function Sidenav_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || Sidenav)(i0.ɵɵinject(i1.BreakpointObserver), i0.ɵɵinject(i2.Router), i0.ɵɵinject(SIDENAV_CONFIG, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: Sidenav,
    factory: Sidenav.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Sidenav, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.BreakpointObserver
  }, {
    type: i2.Router
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [SIDENAV_CONFIG]
    }]
  }], null);
})();

// Import Angular stuff
class SidenavComponent {
  sidenav;
  content;
  destroyer = new ReplaySubject(1);
  constructor(sidenav) {
    this.sidenav = sidenav;
  }
  /**
   *
   */
  ngOnInit() {
    this.sidenav.contentChange.pipe(takeUntil(this.destroyer)).subscribe(component => {
      this.loadContent(component);
    });
  }
  /**
   *
   */
  ngOnDestroy() {
    this.destroyer.next(true);
    this.destroyer.complete();
  }
  /**
   *
   * @param component
   */
  loadContent(component) {
    if (this.content) {
      this.content.viewContainerRef.clear();
      if (component) {
        this.content.viewContainerRef.createComponent(component);
      }
    }
  }
  static ɵfac = function SidenavComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SidenavComponent)(i0.ɵɵdirectiveInject(Sidenav));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SidenavComponent,
    selectors: [["app-sidenav"]],
    viewQuery: function SidenavComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(SidenavContentDirective, 7);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
      }
    },
    decls: 1,
    vars: 0,
    consts: [["sidenav-content", ""]],
    template: function SidenavComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtemplate(0, SidenavComponent_ng_template_0_Template, 0, 0, "ng-template", 0);
      }
    },
    dependencies: [SidenavContentDirective],
    styles: ["[_nghost-%COMP%]{width:100%;height:100%;display:block}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SidenavComponent, [{
    type: Component,
    args: [{
      selector: 'app-sidenav',
      template: "<ng-template sidenav-content></ng-template>\r\n",
      styles: [":host{width:100%;height:100%;display:block}\n"]
    }]
  }], () => [{
    type: Sidenav
  }], {
    content: [{
      type: ViewChild,
      args: [SidenavContentDirective, {
        static: true
      }]
    }]
  });
})();

// Import Angular, CDK and Marerial stuff
class SidenavModule {
  static ɵfac = function SidenavModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SidenavModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: SidenavModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, RouterModule, MatSidenavModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SidenavModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RouterModule, MatSidenavModule],
      declarations: [SidenavComponent, SidenavContentDirective],
      exports: [SidenavComponent]
    }]
  }], null, null);
})();

/**
 * Public surface API of @public/core/ui/sidenav
 */

/**
 * Generated bundle index. Do not edit.
 */

export { SIDENAV_CONFIG, Sidenav, SidenavComponent, SidenavModule };
