// Import Angular stuff

import { Injectable, OnDestroy } from '@angular/core';

// Import RxJS stuff

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Import services used by the UserService

import { LocalStorage } from '@public/core';

import { MemberService } from './member.service';

const USER_KEY = 'currentUser';

export type UserStatus = 'Gæst' | 'Medlem' | 'Træner';

@Injectable({
  providedIn: 'root',
})
export class MushindoUserService implements OnDestroy {
  public userChange: Subject<any> = new Subject<any>();

  private unsubscriber = new Subject<void>();

  constructor(private localStorage: LocalStorage, private memberService: MemberService) {}

  public ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  public getCurrentUser(): any {
    return this.localStorage.get(USER_KEY);
  }

  public setCurrentUser(user: any): void {
    // Handle the situation, where the user is undefined. This will happen when
    // the user has successfully logged out.

    if (!user) {
      if (user !== this.getCurrentUser()) {
        this.userChange.next(user);
      }

      this.localStorage.set(USER_KEY, user);

      return;
    }

    user.status = 'Gæst';

    this.memberService
      .get('me')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(
        member => {
          if (member.instructors) {
            user.status = 'Træner';
          } else {
            user.status = 'Medlem';
          }
          user.level = member.level;

          if (user !== this.getCurrentUser()) {
            this.userChange.next(user);
          }

          this.localStorage.set(USER_KEY, user);
        },
        error => {
          if (user !== this.getCurrentUser()) {
            this.userChange.next(user);
          }

          this.localStorage.set(USER_KEY, user);
        }
      );
  }

  public isMember(): boolean {
    return this.getCurrentUser()?.status === 'Medlem' || this.isInstructors();
  }

  public isInstructors(): boolean {
    return this.getCurrentUser()?.status === 'Træner';
  }
}
