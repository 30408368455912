import { Injectable, Optional, SkipSelf, OnDestroy } from '@angular/core';
import { CanActivate, CanLoad, UrlTree, ActivatedRouteSnapshot, Route } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';

import { Observable, ReplaySubject, takeUntil } from 'rxjs';

import { UserService, SingletonError } from '@public/core';

@Injectable({
  providedIn: 'root',
})
export class LevelGuard implements OnDestroy, CanActivate, CanLoad {
  private currentUser: any;

  private destroyer: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private readonly dialog: MatDialog,
    private userService: UserService,
    @Optional() @SkipSelf() readonly instance: LevelGuard
  ) {
    if (instance) {
      throw new SingletonError(this);
    }

    this.currentUser = this.userService.getCurrentUser();

    this.userService.userChange.pipe(takeUntil(this.destroyer)).subscribe(user => (this.currentUser = user));
  }

  public ngOnDestroy(): void {
    this.destroyer.next(true);
    this.destroyer.complete();
  }

  public canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guard(route);
  }

  public canLoad(route: Route): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guard(route);
  }

  private guard(
    route: Route | ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
    //return !!route.data && !!this.currentUser ? route.data['level'] <= this.currentUser.level : false;
  }
}
