import * as i0 from '@angular/core';
import { NgModule, Injectable, Optional, SkipSelf } from '@angular/core';
import * as i1 from '@public/core';
import { CoreModule, SingletonError } from '@public/core';
import * as i3 from '@angular/common/http';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Subject, of, BehaviorSubject, throwError } from 'rxjs';
import { tap, catchError, switchMap, finalize, filter, take } from 'rxjs/operators';

// Import Angular, CDK and Material stuff
class AuthorizationModule {
  static ɵfac = function AuthorizationModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AuthorizationModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: AuthorizationModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CoreModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthorizationModule, [{
    type: NgModule,
    args: [{
      imports: [CoreModule]
    }]
  }], null, null);
})();

// Import Angular stuff
const ACCESS_TOKEN = 'accessToken';
const REFRESH_TOKEN = 'refreshToken';
class TokenService {
  storage;
  instance;
  constructor(storage, instance) {
    this.storage = storage;
    this.instance = instance;
    if (instance) {
      throw new SingletonError(this);
    }
  }
  getAccessToken() {
    return this.storage.get(ACCESS_TOKEN);
  }
  setAccessToken(token) {
    this.storage.set(ACCESS_TOKEN, token);
  }
  getRefreshToken() {
    return this.storage.get(REFRESH_TOKEN);
  }
  setRefreshToken(token) {
    this.storage.set(REFRESH_TOKEN, token);
  }
  static ɵfac = function TokenService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || TokenService)(i0.ɵɵinject(i1.LocalStorage), i0.ɵɵinject(i1.LocalStorage, 12));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: TokenService,
    factory: TokenService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TokenService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.LocalStorage
  }, {
    type: i1.LocalStorage,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }], null);
})();

// Import Angular stuff
class OAuth2Config {
  path = '/api/tokens';
  auth = 'Basic ' + btoa('api' + ':' + 'secret');
}
class OAuth2Service {
  userService;
  tokenService;
  httpClient;
  config;
  loginSource = new Subject();
  logoutSource = new Subject();
  options;
  constructor(userService, tokenService, httpClient, config) {
    // Use default config unless one has been provided.
    this.userService = userService;
    this.tokenService = tokenService;
    this.httpClient = httpClient;
    this.config = config;
    if (!this.config) {
      this.config = new OAuth2Config();
    }
    this.options = {
      headers: new HttpHeaders({
        Authorization: this.config.auth,
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
  }
  onLogin = this.loginSource.asObservable();
  onLogout = this.logoutSource.asObservable();
  /**
   *
   */
  login(username, password) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    return this.httpClient.post(this.config.path, 'grant_type=password&username=' + username + '&password=' + password, options).pipe(tap({
      next: this.onLoginSuccess,
      error: this.onLoginFailure
    }));
  }
  /**
   *
   */
  logout() {
    if (this.tokenService.getAccessToken()) {
      return this.httpClient.delete(this.config.path + '/' + this.tokenService.getAccessToken()).pipe(tap({
        next: this.onLogoutSuccess,
        error: this.onLogoutFailure
      }));
    } else {
      this.onLogoutSuccess();
      return of(undefined);
    }
  }
  /**
   *
   */
  refreshToken() {
    return this.httpClient.post(this.config.path, 'grant_type=refresh_token&refresh_token=' + this.tokenService.getRefreshToken(), this.options).pipe(tap({
      next: this.onRefreshSuccess,
      error: this.onRefreshFailure
    }));
  }
  /**
   *
   */
  onLoginSuccess = response => {
    this.tokenService.setAccessToken(response.access_token);
    this.tokenService.setRefreshToken(response.refresh_token);
    this.httpClient.get('/api/users/me').subscribe(me => {
      this.userService.setCurrentUser(me);
      this.loginSource.next(me);
    });
  };
  /**
   *
   */
  onLoginFailure = () => {
    this.tokenService.setAccessToken(undefined);
    this.tokenService.setRefreshToken(undefined);
    this.userService.setCurrentUser(undefined);
  };
  /**
   *
   */
  onLogoutSuccess = () => {
    this.tokenService.setAccessToken(undefined);
    this.tokenService.setRefreshToken(undefined);
    this.userService.setCurrentUser(undefined);
    this.logoutSource.next();
  };
  /**
   *
   */
  onLogoutFailure = () => {
    this.tokenService.setAccessToken(undefined);
    this.tokenService.setRefreshToken(undefined);
    this.userService.setCurrentUser(undefined);
  };
  /**
   *
   */
  onRefreshSuccess = response => {
    this.tokenService.setAccessToken(response.access_token);
    this.tokenService.setRefreshToken(response.refresh_token);
  };
  /**
   *
   */
  onRefreshFailure = () => {
    this.tokenService.setAccessToken(undefined);
    this.tokenService.setRefreshToken(undefined);
    this.userService.setCurrentUser(undefined);
  };
  static ɵfac = function OAuth2Service_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || OAuth2Service)(i0.ɵɵinject(i1.UserService), i0.ɵɵinject(TokenService), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(OAuth2Config, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: OAuth2Service,
    factory: OAuth2Service.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OAuth2Service, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.UserService
  }, {
    type: TokenService
  }, {
    type: i3.HttpClient
  }, {
    type: OAuth2Config,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

// Import Angular stuff
class AuthorizationInterceptor {
  injector;
  isRefreshing = false;
  tokenSubject = new BehaviorSubject(undefined);
  oauth2Service;
  tokenService;
  /**
   *
   * @param injector We must use the injector in order to prevent a cyclic
   * dependency due to the fact that the UserService initiates a HTTP request
   * in its constructor.
   */
  constructor(injector) {
    this.injector = injector;
  }
  intercept(request, next) {
    if (this.tokenService === undefined) {
      this.tokenService = this.injector.get(TokenService);
    }
    let authorizedRequest;
    // Clone the request and replace the original headers with cloned headers, updated with the authorization.
    if (request.headers.has('Authorization')) {
      authorizedRequest = request.clone();
    } else {
      authorizedRequest = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.tokenService.getAccessToken()
        }
      });
    }
    // Send cloned request with header to the next handler and catch responses with a 400 or 401 status.
    return next.handle(authorizedRequest).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse) {
        switch (error.status) {
          case 400:
            return this.handle400(error);
          case 401:
            return this.handle401(request, next);
        }
      }
      return throwError(() => new Error(error));
    }));
  }
  handle400(error) {
    if (this.oauth2Service === undefined) {
      this.oauth2Service = this.injector.get(OAuth2Service);
    }
    // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
    if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
      this.oauth2Service.logout();
    }
    return throwError(() => error);
  }
  handle401(request, next) {
    if (this.oauth2Service === undefined) {
      this.oauth2Service = this.injector.get(OAuth2Service);
    }
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(undefined);
      return this.oauth2Service.refreshToken().pipe(switchMap(newToken => {
        if (newToken) {
          this.tokenSubject.next(newToken);
          return next.handle(request.clone({
            setHeaders: {
              Authorization: 'Bearer ' + this.tokenService.getAccessToken()
            }
          }));
        }
        // If we don't get a new token, we are in trouble so logout.
        this.oauth2Service.logout();
        return throwError('DEF');
      }), catchError(error => {
        // If there is an exception calling 'refreshToken', bad news so logout.
        const REFRESH_TIMEOUT_MESSAGE = 'You have been logged out due to inactivity.';
        this.oauth2Service.logout();
        return throwError('ABC');
      }), finalize(() => {
        this.isRefreshing = false;
      }));
    } else {
      return this.tokenSubject.pipe(filter(token => token != null), take(1), switchMap(token => {
        return next.handle(request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + this.tokenService.getAccessToken()
          }
        }));
      }));
    }
  }
  static ɵfac = function AuthorizationInterceptor_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AuthorizationInterceptor)(i0.ɵɵinject(i0.Injector));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AuthorizationInterceptor,
    factory: AuthorizationInterceptor.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthorizationInterceptor, [{
    type: Injectable
  }], () => [{
    type: i0.Injector
  }], null);
})();

/**
 * Public surface API of @public/core/authorization
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AuthorizationInterceptor, AuthorizationModule, OAuth2Config, OAuth2Service, TokenService };
