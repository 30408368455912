// Import Angular stuff

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Import RxJS stuff

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private readonly PATH = '/config';

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  public get(file: string): Observable<any> {
    return this.httpClient.get<any>(`${this.PATH}/${file}.json`);
  }
}
