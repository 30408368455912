<mat-table matSort [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
  <!-- Checkbox (select) column -->

  <ng-container matColumnDef="select">
    <mat-header-cell *matHeaderCellDef>
      <!--
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && allSelected()"
                    [indeterminate]="selection.hasValue() && !allSelected()"
                    [aria-label]="checkboxLabel()">
      </mat-checkbox>
      -->
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? selection.toggle(row) : null"
        [checked]="selection.isSelected(row)"
        [aria-label]="checkboxLabel(row)"
      >
      </mat-checkbox>
    </mat-cell>
  </ng-container>

  <!-- Id column -->

  <ng-container cdkColumnDef="id">
    <mat-header-cell *cdkHeaderCellDef>ID</mat-header-cell>
    <mat-cell *cdkCellDef="let row">{{ row.key }}</mat-cell>
  </ng-container>

  <!-- Name column -->

  <ng-container cdkColumnDef="name">
    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Navn</mat-header-cell>
    <mat-cell *cdkCellDef="let row">{{ row.name }}</mat-cell>
  </ng-container>

  <!-- Level column -->

  <ng-container cdkColumnDef="level">
    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Grad</mat-header-cell>
    <mat-cell *cdkCellDef="let row">{{ row.level > 0 ? (row.level | levelToText) : '' }}</mat-cell>
  </ng-container>

  <!-- Group column -->

  <ng-container cdkColumnDef="group">
    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Gruppe</mat-header-cell>
    <mat-cell *cdkCellDef="let row">{{ row.group }}</mat-cell>
  </ng-container>

  <!-- Payment column -->

  <ng-container cdkColumnDef="payment">
    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Betalt</mat-header-cell>
    <mat-cell *cdkCellDef="let row">{{ row.payment }}</mat-cell>
  </ng-container>

  <!-- Row definitions -->

  <mat-header-row *cdkHeaderRowDef="columns"></mat-header-row>
  <mat-row mat-list-item *cdkRowDef="let row; columns: columns" (click)="selection.toggle(row)"> </mat-row>
</mat-table>

<mat-paginator [pageSizeOptions]="[20, 50, 100]"></mat-paginator>
