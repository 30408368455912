import { Component, Input, ViewChild } from '@angular/core';

import { SelectionModel } from '@angular/cdk/collections';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'member-selection',
  styleUrls: ['member-selection.component.scss'],
  templateUrl: 'member-selection.component.html',
})
export class MemberSelectionComponent {
  @ViewChild(MatSort)
  public sort!: MatSort;

  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;

  @Input()
  public columns = ['select', 'name', 'level', 'group'];

  public get dataSource() {
    return this._dataSource;
  }

  @Input()
  public set dataSource(value: MatTableDataSource<any>) {
    this._dataSource = value;

    if (value) {
      this._dataSource.sort = this.sort;
      this._dataSource.paginator = this.paginator;
    }
  }

  private _dataSource!: MatTableDataSource<any>;

  public get selected(): any[] {
    return this.selection.selected;
  }

  @Input()
  public set selected(value: any[]) {
    value.forEach(row => this.selection.select(row));
  }

  public readonly selection = new SelectionModel<any>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */

  public allSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource ? this.dataSource.data.length : 0;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */

  public masterToggle() {
    this.allSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */

  public checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.allSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
}
