import { Injectable, Optional, SkipSelf, OnDestroy } from '@angular/core';
import { CanActivate, CanLoad, UrlTree } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';

import { Observable, ReplaySubject, takeUntil } from 'rxjs';

import { UserService, SingletonError } from '@public/core';

@Injectable({
  providedIn: 'root',
})
export class MemberGuard implements OnDestroy, CanActivate, CanLoad {
  private currentUser: any;

  private destroyer: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private readonly dialog: MatDialog,
    private userService: UserService,
    @Optional() @SkipSelf() readonly instance: MemberGuard
  ) {
    if (instance) {
      throw new SingletonError(this);
    }

    this.currentUser = this.userService.getCurrentUser();

    this.userService.userChange.pipe(takeUntil(this.destroyer)).subscribe(user => (this.currentUser = user));
  }

  public ngOnDestroy(): void {
    this.destroyer.next(true);
    this.destroyer.complete();
  }

  public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guard();
  }

  public canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guard();
  }

  private guard(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
    /* 
    if (this.currentUser) {
      return !!this.currentUser.roles.find((role: any) => role.code === 'MEMBER');
    }

    return this.dialog
      .open(LoginDialogComponent, { disableClose: true })
      .afterClosed()
      .pipe(
        combineLatestWith(this.userService.userChange),
        map(([_, user]) => {
          return !!user;
        })
      );
      */
  }
}
